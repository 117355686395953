import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const image1 = require("../../../assets/images/blog_15/b15_1.webp");
const image2 = require("../../../assets/images/blog_15/b15_2.jpg");
const image3 = require("../../../assets/images/blog_15/b15_3.jpg");
const image4 = require("../../../assets/images/blog_15/b15_4.jpg");
const image5 = require("../../../assets/images/blog_15/b15_5.jpg");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Integrate OneDrive with Chatbot | Workativ Blog"
        description="Effortlessly create Microsoft OneDrive workflow automations and deploy a contextual chatbot for employee support."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Integrate and Automate your favorite apps with
                            Microsoft OneDrive using Workativ
                          </h1>
                          <img src={image1} className="mb-4"></img>
                        </div>
                      </div>
                      <div className=" market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          A document management process automation enables
                          organizing, securing, capturing, digitizing, tagging,
                          approving, and completing tasks with your business
                          files.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          For instance, Workativ’s document management process
                          automation on Slack or Microsoft Teams app make it
                          possible to access files, collaborate, track, and
                          securely send sensitive content and information from
                          anywhere there is an internet connection. It also
                          gives users the ability to share very large sets of
                          files securely via their mobile phones. Although
                          accessibility and security were once viewed as
                          mutually exclusive benefits of technology solutions,
                          Workativ’s document management process automation
                          brings each of these benefits together in a single
                          functional solution.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ, employees can access and store
                          office-related information on their own mobile
                          devices, and from anywhere there is an internet
                          connection with high security. So, what is Workativ
                          exactly?
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Workativ is a no-code platform for scaling your IT &
                          HR support with AI-based self-service automation.
                          Delivering remote support for employees is more
                          important than ever and Workativ can help automate
                          employee support with intelligent chatbot and process
                          automation, out-of-the-box. Workativ can be added to
                          any of your instant messaging apps like Slack or
                          Microsoft Teams so that employees can self-serve their
                          IT/HR issues, on-the-go, anytime.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          If your business is using a document management
                          software like Microsoft OneDrive, it is high-time you
                          integrated it with Workativ to reap the benefits of
                          automation.
                        </p>
                      </div>
                      <div>
                        <h2 class="font-section-sub-header-small">
                          How Workativ streamlines key OneDrive processes
                        </h2>

                        <p class="font-section-normal-text line-height-2">
                          By integrating Microsoft OneDrive and Workativ, you
                          streamline your workplace IT support to connect
                          employees, processes, and automations into a
                          transparent workflow. Let’s take a look at some of the
                          automations you get with Workativ and Microsoft
                          OneDrive integration.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          1.Get File Link
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Going through a plethora of messages on your Slack and
                          Microsoft Teams, trying to find that one file link
                          that she/he shared with the team on your team’s
                          channel… Just reading this alone is exhausting, right?
                          Not anymore. Workativ’s Onedrive AI process
                          automations makes it easy to retrieve a files’ link
                          just by mentioning the file’s name and asking Workativ
                          to bring it up for you. Sounds so simple, right?
                        </p>

                        <h5 class="font-section-sub-header-small">
                          2. Get Documents
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Download documents and view them offline anywhere,
                          anytime with Workativ’s OneDrive automation! You’ll
                          just have to bring up Workativ on your Slack or
                          Microsoft Teams app on your mobile device and Workativ
                          will take care of the rest for you. Ask Workativ to
                          retrieve a document for download and it will do that
                          in a jiff! That’s Workativ’s magic for you.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          Popular Microsoft OneDrive Integrations
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ, you can connect multiple apps and set
                          up an automation involving a synergistic workflow of
                          tasks on those apps. Let’s take a look at some of the
                          apps you can combine with Microsoft OneDrive using
                          Workativ.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          1. ServiceNow OneDrive Integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ, you can set up a OneDrive and
                          ServiceNow automation such that, in case the OneDrive
                          automation doesn’t behave as intended, a ticket gets
                          automatically created on ServiceNow giving the help
                          desk team a head-up about the situation so that help
                          desk agents can take over and look into the issue
                          immediately.
                        </p>
                        <img loading="lazy" src={image2} className="mb-5"></img>

                        <h5 class="font-section-sub-header-small">
                          2. Jira OneDrive Integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          You can set up a OneDrive and Jira automation using
                          Workativ such that, whenever there’s an important
                          product-related update to be shared with the dev team,
                          after the product manager uploads the update document
                          into OneDrive, it gets shared with the entire dev team
                          via automatic Jira ticket creation by Workativ. Sounds
                          so simple, right?
                        </p>
                        <img loading="lazy" src={image3} className="mb-5"></img>

                        <h5 class="font-section-sub-header-small">
                          3. Office 365, OneDrive, Gmail and Slack Integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Whenever there’s a new employee being onboarded, help
                          desk agents can set up an automation on Workativ by
                          integrating Office 365, Gmail, Slack and OneDrive such
                          that, once the employee gets added to the company’s
                          Office 365 emailing list, the employee guidelines
                          document from OneDrive gets shared with the employee
                          on their Slack channel as well as gets sent to the
                          employees’ email id via Gmail by Workativ. This
                          prevents the employee from going on a search spree on
                          what are the company guidelines to be followed.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          How to automate OneDrive processes with Workativ
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Now that we’ve seen the endless possibilities of what
                          integrating Workativ and OneDrive and your other apps
                          can do for you, let’s see how you can go about the
                          same.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          1. Workflow builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Workativ assistant comes with easy-to-use no-code
                          workflow builder to help you build simple to complex
                          app-based automations for chatbot in minutes.
                          Integrate chatbot with apps, use pre-built workflows
                          from marketplace, approve requests, connect chatbot
                          with on-prem apps and much more.
                        </p>

                        <img loading="lazy" src={image4} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          2. Chatbot Builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Our easy-to-use no-code chatbot builder helps you
                          build and manage simple to complex conversations,
                          FAQs, integrate app workflows, and personalize your
                          bot in minutes. Deliver autonomous workplace support
                          with purpose built intelligent chatbots.
                        </p>
                        <img loading="lazy" src={image5} className="mb-3"></img>
                      </div>

                      <NocodeWrapper />
                      <h6 className="font-section-sub-header-small-bold">
                        Benefits
                      </h6>
                      <p class="font-section-normal-text line-height-2">
                        Now the benefits are enormous when it comes to
                        integrating and automating OneDrive tasks with Workativ.
                      </p>

                      <div className="market_wrapper_page ">
                        <h6 className="font-section-sub-header-small ">
                          1. Free yourself from document disarray
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Workativ’s document management process automation
                          reduces the load on document management. Document
                          management automation bypasses many steps that would
                          otherwise be dependent on the document management
                          software. Some of these steps include manually
                          searching and retrieving documents or going through a
                          plethora of messages to find a file’s link.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          2. Microsoft OneDrive Employee Self Service
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Workativ provides users with a holistic self-service
                          interface for all of their IT needs. By integrating
                          OneDrive with Workativ, end users can retrieve and
                          view files with ease on Workativ’s OneDrive chatbot.
                          This integration saves time and greatly enhances the
                          user experience.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          3. Allows you to automate repetitive processes
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Repetitive OneDrive tasks like retrieving a document
                          or a file’s link can be automated with Workativ so
                          that employees have access to the required documents
                          whenever they need, in office or on-the-road.
                        </p>

                        <h5 className="font-section-sub-header-small">
                          4. Creating a Synergy between your Apps
                        </h5>

                        <p class="font-section-normal-text line-height-2">
                          You can combine your other apps with Microsoft
                          OneDrive creating an automated workflow that does much
                          more with those apps in just a single run.
                        </p>

                        <p class="font-section-normal-text-medium line-height-2">
                          Liked what you read about Workativ? Try Workativ today
                          by signing up for a FREE trial at workativ.com and
                          experience the difference yourself.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                {" "}
                                Conversational AI Tools—Top 6 Tools To Build
                                Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai">
                                Chatbot Best Practices for designing a
                                Conversational Experience with Workativ
                                Assistant
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}
            {/* 
            <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free OneDrive Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="onedrive chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
